<template>
	<div>
		<a-spin :spinning="loading">
			<div class="flex alcenter">
				<img v-if="account.face!=null" style="width: 60px;height: 60px; border-radius: 30px;" :src="account.face" />
				<img v-else style="width: 60px;height: 60px; border-radius: 30px;" src="../assets/image/woyaoyou_head_defult_2x.png" />
				<div class="ml15 flex alcenter">
					<div class="ft14 cl-main ftw600">{{account.username}}</div>
					<a-button @click="showPwd = true" type="link">修改密码</a-button>
					<!-- <div class="mt8 ft12 cl-notice">{{showT}}</div> -->
				</div>
			</div>
			<div class="mt40 flex alcenter">
				<span class="ft20 ftw600 cl-main">我的商铺</span>
			</div>
			<div class="mt30 mendian-list">
				<div  @click="manageAct()" :class="{dis:mendian == null}" class="mendian-item flex space" >
					<div>
						<div class="flex alcenter">
							<div class=" ft16 ftw600 cl-main text-over4">
								{{mendian == null ? '该门店已注销' :  mendian.name}}
							</div>
							<div class="ft14 ftw400 cl-notice ml5 text-over4">
								({{mendian == null ? '' :  mendian.info == null ? '' : mendian.info}})
							</div>
						</div>
						<div class="mt12 ft12 cl-info">开通时间：{{mendian.add_time}}</div>
					</div>
				</div>
				
				<!--<div @click="addMendian" class="mendian-item add flex alcenter center">
					<i class="iconfont iconadd_big cl-theme"></i>
					<span class="ft16 cl-theme ftw600 ml10">创建商铺</span>
				</div>-->
			</div>
			
		
		</a-spin>
		
		 <a-modal v-model="showPwd" title="设置密码" on-ok="handleOk">
		      <template slot="footer">
		        <a-button key="back" @click="handlePwdCancel">
		          取消
		        </a-button>
		        <a-button key="submit" type="primary" :loading="pwdloading" @click="handlePwdOk">
		          确认
		        </a-button>
		      </template>
		      <a-form-model :model="pwd" :label-col="labelCol" :wrapper-col="wrapperCol">
		          <a-form-model-item label="输入密码">
		            <a-input type="password" v-model="pwd.pwd" />
		          </a-form-model-item>
				  <a-form-model-item label="再次确认">
				    <a-input type="password"  v-model="pwd.repwd" />
				  </a-form-model-item>
			  </a-form-model>	  
		    </a-modal>
	</div>
</template>

<script>
	export default{
		
		data(){
			return {
			    labelCol: { span: 4 },
			    wrapperCol: { span: 14 },
				loading:false,
				showPwd:false,
				pwd:{
					pwd:'',
					repwd:'',
				},
				pwdloading:false,
				account:{
					mobile:'',
					username:'',
					face:null,
				},
				mendian:{},
			}
		},
		
		created(){
			this.getLists();
		},
		methods:{
			handlePwdCancel(){
				this.showPwd = false;
			},
			handlePwdOk(){
				if(this.pwdloading == true) return;
				this.pwdloading = true;
				this.$http.api('platform/account/editPwd',{
					pwd:this.pwd.pwd,
					repwd:this.pwd.repwd
				}).then(res=>{
					this.pwdloading = false;
					this.$message.success('密码设置成功');
					this.showPwd = false;
				}).catch(res=>{
					this.pwdloading = false;
				});
			},
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('platform/account/home').then(res=>{
					this.account=res.account;
					this.mendian=res.mendian;
                    let timestamp = this.mendian.add_time;
                    let date = new Date(timestamp * 1000); // JavaScript 使用毫秒为单位的时间戳，所以需要乘以1000
                    this.mendian.add_time = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();					if(this.account.password == null || this.account.password == ''){
                        this.showPwd = true;
					}
				}).catch(res=>{
					console.log(res);
				}).finally(()=>{
					this.loading=false;
				})
			},
			
			manageAct(){
				if(this.mendian == null){
					this.$message.error('该门店已注销');
					return false;
				}
				let base_url=this.mendian.development_address;
				// let base_url='http://localhost:9190/beautiful#/';
				window.open(base_url  + 'auth?shop_token='+ encodeURIComponent(this.mendian.shop_token));
			},
			handleOk(){
				this.showForm = false;
			},
			handleCancel(){
				this.showForm = false;
			},
			addMendian(){
				// this.showForm = true;
				this.$router.push('/mendian/create');
			},
			
		}
	}
</script>

<style>
	.mendian-list{
		display: grid;
		grid-template-columns: repeat(3,380px);   
		grid-template-rows: repeat(3,100px); 
		grid-column-gap: 30px; 
		grid-row-gap: 30px;
	}
	.mendian-item{
		width: 380px;
		height: 100px;
		background: #FFFFFF;
		border-radius: 8px;
		border-left: 8px solid #EBEDF5;
		padding: 24px 16px;
		cursor: pointer;
		transition: all 0.3s;
	}
	.mendian-item.dis{
		opacity: 0.5;
	}
	.mendian-item .tag{
		width: 52px;
		height: 20px;
		border-radius: 10px;
		border: 1px solid #4772FF;
		text-align: center;
		line-height: 18px;
		font-size: 12px;
		color:#4772FF;
	}
	.mendian-item:hover{
		transform: translateY(-10px);
	}
	.mendian-item.add{
		border: 1px dashed #4772FF;
	}
	
</style>